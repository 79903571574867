import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag, faAt, faBuilding, faPhone  } from '@fortawesome/free-solid-svg-icons'

import logoBlack from '../media/maiqqi-logo-text-black.jpg'

import './Yhteystiedot.scss';

function Yhteystiedot() {

  return (
    <div className="Yhteystiedot" id="Yhteystiedot">
      <h2>Yhteystiedot</h2>

        <div className="grid">

              <div id="gridTekstit">
                <h3>Maiqqi Oy</h3><br/>
                <p><FontAwesomeIcon icon={faPhone} id="iconYhteystiedot"/><a id="contactLink" href="tel:+358 45 3433 001">+358 45 3433 001</a></p>
                <p><FontAwesomeIcon icon={faAt} id="iconYhteystiedot" /><a id="contactLink" href="mailto:mika@maiqqi.fi">mika@maiqqi.fi</a></p><br/>
                <p><FontAwesomeIcon icon={faBuilding} id="iconYhteystiedot" />01640 Vantaa</p>
                <p><FontAwesomeIcon icon={faHashtag} id="iconYhteystiedot" />Y-tunnus: 3101925-7</p>

              </div>

              <div id="gridKuva">
                <img src={logoBlack} alt="logo"/>
              </div>

      </div>
    </div>

  );
}

export default Yhteystiedot;