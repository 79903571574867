import React from 'react'

import seppo from '../media/seppo-kopsa-jatke.jpg'
import jatke from '../media/Jatke-logo-violetti-cmyk_0.png'

import './Referenssit.scss'

function Referenssit() {
  return (
    <div className="Referenssit" id="Referenssit">
      <div id="Referenssit0">
        <h2>Referenssit</h2>
      </div>

      <div id="ReferenssitContainer">
        <div id="Referenssit1">
          <p>
            <strong>
              Rakennusyhtiö Jatkeelle toteutettiin ostojen analysointityökalu
              vuonna 2020 Maiqqi Oy:n kanssa. Jatke on ollut yhteistyön
              tulokseen tyytyväinen.
            </strong>
          </p>
          <hr />
          <p>
            <em>
              "Aloitettuani Jatkeella hankintajohtajana ensimmäisiä tehtäviäni
              oli käydä yrityksen ostot lävitse. Tehtävään tarvittiin
              luotettavaa kumppania ja Maiqqi Oy:n Mika Itäluoma valittiin tähän
              kumppaniksi.
            </em>
          </p>
          <p>
            <em>
              Heidän avulla pystyimme nopeasti kehittämään PowerBi:tä hyödyntäen
              analysointityökalun, jolla pääsemme seuraamaan sekä analysoimaan
              yrityksemme ostoja online aivan työmaa tasolle asti.
            </em>
          </p>
          <p>
            <em>
              Työskentely Maiqqi Oy:n kanssa oli helppoa ja nopeaa. Tarvittavat
              palaverit onnistuivat nopeasti ja keskustelu on ollut avointa sekä
              innovoivaa."
            </em>
          </p>
          <h4>Seppo Kopsa, Konsernin hankintajohtaja</h4>
        </div>

        <div id="Referenssit2">
          <img src={jatke} alt="" />
          <img id="person" src={seppo} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Referenssit
