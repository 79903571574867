import React from 'react'
import { Route, Switch, Redirect, Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

import Menu from './components/Menu.jsx'
import Evasteet from './components/Evasteet.jsx'
import logoTextWeb from './media/maiqqi-text-web.png'
import logoTextMobile from './media/maiqqi-text-mobile.png'
// import SocialMediaButtons from './components/SocialMediaButtons.jsx'
import TietosuojaselostePage from './pages/TietosuojaselostePage.jsx'

import './App.scss'
import Frontpage from './pages/Frontpage'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingDone: false,
      loadingValue: 0,
      menuOpen: false,
      menuOpenedAt: 0,
      evasteetOK: false,
      clickHappened: false,
      pathName: null,
      width: 0,
    }

    this.evasteetToggle = this.evasteetToggle.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    function getCookie(cname) {
      let name = cname + '='
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }

    const checkCookie = (key) => {
      let value = getCookie(key)
      if (key === 'success' && value === 'true') {
        alert('Yhteydenottopyyntö vastaanotettu. Olemme teihin yhteydessä mahdollisimman pian.')
        document.cookie = 'success=false'
      }
      if (key === 'cookiesAccepted' && value === 'true') {
        this.evasteetToggle()
      }
    }

    checkCookie('success')
    checkCookie('cookiesAccepted')
  }

  componentDidUpdate() {
    if (this.state.pathName !== document.location.pathname) {
      this.setState({
        pathName: document.location.pathname,
      })
    }

    if (this.state.menuOpen && this.state.menuOpenedAt + 100 < window.pageYOffset) {
      this.setState({
        menuOpen: false,
      })
    }

    if (this.state.menuOpen && this.state.menuOpenedAt - 100 > window.pageYOffset) {
      this.setState({
        menuOpen: false,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  menuToggle() {
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuOpenedAt: window.pageYOffset,
    })
  }

  menuClose() {
    this.setState({
      menuOpen: false,
    })
  }

  evasteetToggle = () => {
    this.setState(
      {
        evasteetOK: true,
      },
      this.cookiesAccepted
    )
  }

  cookiesAccepted() {
    document.cookie = 'cookiesAccepted=true'
  }

  clickCheck(e) {
    if (this.state.menuOpen === true) {
      if (e.target.id !== 'NavBar' && e.target.id !== 'Menu') {
        this.setState({
          menuOpen: false,
        })
      }
    }
  }

  render() {
    const { width, menuOpen, evasteetOK } = this.state

    return (
      <div className='App' onClick={(e) => this.clickCheck(e)}>
        {width < 1025 ? (
          <div className='NavBar' id='NavBar'>
            <img src={logoTextMobile} alt='logoText' id='NavBar' />
            <button id='chevronButton' onClick={() => this.menuToggle()}>
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                id='chevron'
                style={menuOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
              />
            </button>
          </div>
        ) : (
          <div className='NavBarWeb' id='NavBarWeb'>
            <button
              id={menuOpen ? 'menuButtonOpen' : 'menuButton'}
              onClick={() => this.menuToggle()}>
              <p>MENU</p>
            </button>
            <img src={logoTextWeb} alt='logoTextWeb' id='NavBarWeb' />
          </div>
        )}

        {menuOpen ? (
          <Menu pathName={this.state.pathName} menuToggle={() => this.menuToggle()} />
        ) : null}

        {/* { window.innerWidth < 1025 ? null : <SocialMediaButtons/>} */}

        <Switch>
          <Route exact path='/' render={() => <Frontpage />} />
          <Route exact path='/tietosuojaseloste' render={() => <TietosuojaselostePage />} />
          <Route render={() => <Redirect to='/' />} />
        </Switch>

        <div className='copyright'>
          <Link to='/tietosuojaseloste'>Tietosuojaseloste</Link>
          <p>© Maiqqi Oy 2023</p>
        </div>

        {evasteetOK ? null : <Evasteet evasteetToggle={() => this.evasteetToggle()} />}
      </div>
    )
  }
}

export default App
