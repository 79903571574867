import React from 'react'
import { Link } from "react-router-dom"

import './Tietosuojaseloste.scss';

function Tietosuojaseloste() {

  return (
    <div className="Tietosuojaseloste">
      <p><strong>MAIQQI OY TIETOSUOJASELOSTE (PÄIVITETTY 19.5.2020)</strong></p>
      <p>Henkilötietoja käsitellään ja suojellaan asetusten ja lakien mukaisesti. Kerromme tässä tietosuojaselosteessa ne toimintatavat, joita noudatamme kerätessämme ja käsitellessämme henkilötietoja.</p>
      <br/>

      <p><strong>REKISTERINPITÄJÄ</strong></p>
      <p>Maiqqi Oy</p>
      <p>Y-tunnus: 3101925-7</p>
      <br/>
      
      <p><strong>YLEISTÄ TIETOSUOJAKÄYTÄNNÖSTÄMME</strong></p>
      <p>Käsittelemme henkilötietoja Tietosuojalain (1050/2018), Euroopan Unionin yleisen tietosuoja-asetuksen (2016/679) sekä tämän tietosuojaselosteen mukaisesti. 
      Pyrimme jatkuvasti perehtymään tietosuojaa koskeviin vaatimuksiin ja ohjeistuksiin, jotta henkilöstömme osaa toimia tietosuojaa koskevissa asioissa vastuullisesti ja lakien mukaisesti.</p>
      <br/>
      
      <p><strong>MITÄ HENKILÖTIETOJA KERÄÄMME JA MIHIN TARKOITUKSEEN?</strong></p>
      <p>Keräämme henkilötietoja vain ennalta määriteltyihin tarkoituksiin, jotka ovat tarkemmin kuvattu seuraavaksi.</p>
      <p>Yhteydenottopyyntöjä jätettäessä käsittelemme seuraavia tietoja:</p>
      <ul>
        <li>Etu- ja sukunimi</li>
        <li>Yrityksen nimi</li>
        <li>Sähköpostiosoite</li>
        <li>Puhelinnumero</li>   
        <li>Tekstikentän ”Viesti” tietoja</li>
      </ul>
      <p>
        Tietoja käytetään Maiqqi Oy:n ja kyseisen yrityksen tai henkilön väliseen yhteydenpitoon, kuten tarjouksen lähettämiseen annettuun sähköpostiosoitteeseen tai asiakkuus- / kumppanuussuhteen hoitamiseen. 
        Kerätyistä tiedoista saatetaan muodostaa erilaisia keskitettyjä analytiikka- ja tilastokokonaisuuksia, jotka eivät kuitenkaan sisällä yksilöiviä henkilötietoja, vaan esimerkiksi yhteydenottopyyntöjen lukumääriä, 
        päivämääriä ja tarjouspyyntölomakkeessa olevien alasvetovalikoiden valintatietoja.</p>
        <br/>
      
      <p><strong>HENKILÖTIETOJEN SUOJAUS</strong></p>
      <p>Henkilötiedot tallennetaan suojattuun paikkaan, minne on pääsy vain Wilcode Oy:n henkilökunnalla. Maiqqi Oy vastaa siitä, että henkilötiedot eivät päädy kyseisen ympäristön ulkopuolelle ilman vähintään saman tason suojausmenetelmiä.</p>
      <br/>
      
      <p><strong>KUINKA KAUAN KÄSITTELEMME HENKILÖTIETOJA</strong></p>
      <p>Käsittelemme henkilötietoja vain niin kauan, kuin meillä on lakisääteinen oikeus tai velvollisuus käsitellä henkilötietoja. 
        Arvioimme tallentamiemme tietojen tarpeellisuutta säännöllisesti ja poistamme sellaiset tiedot, jotka eivät ole enää tarpeellisia.</p>
        <br/>
      
      <p><strong>TIETOJEN SIIRTO TAI LUOVUTUS</strong></p>
      <p>Kerättyjä tietoja ei siirretä tai luovuteta eteenpäin Maiqqi Oy:n toimesta ilman kyseisen henkilön ja/tai yrityksen suostumusta.</p>
      <br/>
      
      <p><strong>EVÄSTEKÄYTÄNTÖ</strong></p>
      <p>Eväste (englanniksi Cookie) on pieni tekstitiedosto, jonka verkkoselain tallentaa kävijän päätelaitteelle verkkosivustoa käytettäessä. 
        Käytämme verkkosivullamme evästeitä kerätäksemme tietoa sivuston käytöstä. Näiden tietojen avulla pyrimme analysoimaan ja kehittämään sivustoa sekä palveluitamme. 
        Käyttäjää ei voida tunnistaa pelkkien evästeiden avulla. Jatkamalla sivuston käyttöä, hyväksyt evästekäytäntömme. Voit estää evästeiden käytön laitteellasi muuttamalla verkkoselaimen asetuksia. 
        Huomioi, että jos et hyväksy evästeiden käyttöä, verkkosivusto ei välttämättä toimi oikein laitteellasi.</p>
        <br/>
      
      <p><strong>TIETOSUOJASELOSTEEN PÄIVITTÄMINEN</strong></p>
      <p>Päivitämme tätä tietosuojaselostetta aina kun henkilötietojen käsittelyssä tai sovellettavassa lainsäädännössä tapahtuu muutoksia. Lisäksi saatamme päivittää tietosuojaselostetta, kun kehitämme verkkosivustoamme tai palveluitamme. 
        Tietosuojaselosteen viimeisin versio on saatavilla tällä verkkosivustollamme (<Link to="/tietosuojaseloste" alt="tietosuojaseloste" onClick={() => window.scrollTo({left:0,top:0,behavior:'smooth'})}>maiqqi.fi/tietosuojaseloste</Link>).</p>

    </div>

  );
}

export default Tietosuojaseloste;