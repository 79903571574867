import React from 'react'
import { Link } from "react-router-dom"

import './Evasteet.scss';

function Evasteet(props) {

  return (
    <div className="Evasteet">
      <p>Käytämme verkkosivullamme evästeitä kehittääksemme sivustoa ja palveluitamme. 
        Jatkamalla sivuston käyttöä, hyväksyt <Link to="/tietosuojaseloste">evästekäytäntömme</Link>.</p>
        <button onClick={() => props.evasteetToggle()}>
          <p>OK</p>
        </button>
    </div>
  );
}

export default Evasteet;