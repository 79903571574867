import React from 'react'

import logoBlack from '../media/maiqqi-logo-text-black.jpg'

import './Landing.scss';

function Landing() {

  return (
    <div className="Landing" id="Landing">

          <div id="grid1">
              <h2>Data-analytiikka ja raportointi nopeasti, kustannustehokkaasti sekä laadukkaasti<br/>- yhdestä paikasta.</h2>
          </div>

          <div id="grid2">

                <img src={logoBlack} alt="logo" id="logo"/>               

          </div>

      </div>

  );
}

export default Landing;