import React from 'react'

import Tietosuojaseloste from '../components/Tietosuojaseloste.jsx'

class TietosuojaselostePage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
    }

render(){
  return (
    <>
    <Tietosuojaseloste/>
    </>
  )
}
  
}

export default TietosuojaselostePage;