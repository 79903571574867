import React from 'react'

import mika from '../media/mika-italuoma-maiqqi.jpg'
import aki from '../media/aki.png'

import './Yritys.scss'

function Yritys() {
  return (
    <div className='Yritys' id='Yritys'>
      <div id='yritys0'>
        <h2>Yritys</h2>
        <p>
          <strong>Maiqqi Oy</strong> on vuodesta 2020 aktiivisesti toiminut liikejohdon
          data-analytiikan ja raportoinnin kehittämisen ohjelmistoyritys.
        </p>
        <p>
          Perustajana ja pääkonsulttina toimii Tuotantotalouden insinööri{' '}
          <strong>Mika Itäluoma</strong>.
        </p>
      </div>

      <div id='yritysContainer'>
        <div id='yritys1'>
          <h3>Mika, Head of Analytics</h3>
          <p>
            Mika tarjoaa yrityksille nykyaikaista raportointia nopeasti ja luotettavasti. Hän on
            toiminut BI-raportoinnin parissa aktiivisesti vuodesta 2016 alkaen ja kehittänyt useita
            raportteja eri toimintaympäristöihin ja tarpeisiin.
          </p>
          <p>
            Mika on nähnyt että useissa yrityksissä raportointi on hoidettu tavalla, jossa on vielä
            runsaasti kehitettävää ja tuo tässä suhteessa vastauksen Maiqqi Oy:n tarjonnan kautta.
          </p>
          <p>
            Ole rohkeasti yhteydessä, niin pohditaan yhdessä paras tapa tuottaa teille
            raportointipalveluita.
          </p>
        </div>

        <div id='yritys2'>
          <img src={mika} alt='' />
        </div>
      </div>

      <div id='yritysContainer'>
        <div id='yritys1'>
          <h3>Aki, IT-Consultant</h3>
          <p>
            Aki tarjoaa monipuolista IT-konsultointia yrityksen toimintaa tehostavien IT-ratkaisujen
            kautta. Akilla on vahva kokemus Office 365-ympäristöstä.
          </p>
        </div>

        <div id='yritys2'>
          <img src={aki} alt='' />
        </div>
      </div>
    </div>
  )
}

export default Yritys
