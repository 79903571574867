import React from 'react'

import analytics from '../media/analytics-256.png'
import teacher from '../media/teacher-256.png'
import business from '../media/business-icon.png'

import './Palvelut.scss'

class Palvelut extends React.Component {
  scrollToElement2 = (id) => {
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const coordY =
      window.scrollY + document.getElementById(id).getBoundingClientRect().top
    const quickMaths = coordY - viewportHeight / 7
    window.scrollTo({ left: 0, top: quickMaths, behavior: 'smooth' })
  }

  scrollToElement = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  render() {
    if (window.innerWidth >= 1025) {
      return (
        <div className="Palvelut" id="Palvelut">
          <h2>Palvelut</h2>
          <p>
            Nykypäivänä data on valtaa. Dataa on niin yrityksen sisällä kuin
            ulkopuolella runsaita määriä. Kuinka yrityksesi hoitaa tiedolla
            johtamisen? Missiomme on saada datanne hyödynnettyä johtamisen
            tueksi parhaalla mahdollisella tavalla.
          </p>
          <p>
            Opi johtamaan yritystä datan avulla ja hyödy vaikuttavista
            raporteista. Räätälöimme yrityksellesi sopivan toteutuksen
            Microsoftin tarjoamilla BI -työkaluilla.
          </p>
          <p>
            Maiqqi Oy tarjoaa laadukkaat raportit sekä tiedolla johtamisen
            palveluna. Teemme räätälöityjä raportointiratkaisuja yrityksille
            sekä yhteisöille.
          </p>
          <br />

          <div id="palvelutSlotit">
            <div id="slot" style={{ padding: '0 15px', width: '33%' }}>
              <img src={analytics} alt="" />
              <h3>Raportointipalvelut palveluna</h3>
              <p>
                Raportointipalvelut automatisoituna ja aina saatavillasi
                laitteesta riippumatta.
              </p>
            </div>

            <div id="slot" style={{ padding: '0 15px', width: '33%' }}>
              <img src={teacher} alt="" />
              <h3>Kouluttaminen + Työpajat</h3>
              <p>Kaikki mitä sinun pitää tietää Microsoftin BI- Työkaluista.</p>
            </div>

            <div id="slot" style={{ padding: '0 15px', width: '33%' }}>
              <img src={business} alt="" />
              <h3>IT-Konsultointi</h3>
              <p>Monipuolinen IT-konsultointi Office 365 -ympäristöstä.</p>
            </div>
          </div>

          <hr
            style={{ width: '100%', margin: '0', border: '1px solid #000' }}
          />
          <p style={{ paddingTop: '40px' }}>
            <strong>
              <a
                id="contactLink"
                onClick={() => this.scrollToElement('Yhteydenottopyynto')}
              >
                Ota yhteyttä
              </a>{' '}
              ja jutellaan yrityksesi tarpeista lisää!
            </strong>
          </p>
        </div>
      )
    } else {
      return (
        <div className="Palvelut" id="Palvelut">
          <h2>Palvelut</h2>
          <p>
            Nykypäivänä data on valtaa. Dataa on niin yrityksen sisällä kuin
            ulkopuolella runsaita määriä. Kuinka yrityksesi hoitaa tiedolla
            johtamisen? Missiomme on saada datanne hyödynnettyä johtamisen
            tueksi parhaalla mahdollisella tavalla.
          </p>
          <p>
            Opi johtamaan yritystä datan avulla ja hyödy vaikuttavista
            raporteista. Räätälöimme yrityksellesi sopivan toteutuksen
            Microsoftin tarjoamilla BI -työkaluilla.
          </p>
          <p>
            Maiqqi Oy tarjoaa laadukkaat raportit sekä tiedolla johtamisen
            palveluna. Teemme räätälöityjä raportointiratkaisuja yrityksille
            sekä yhteisöille.
          </p>
          <br />

          <div id="palvelutSlotit">
            <div id="slot" style={{ padding: '0 15px', width: '80%' }}>
              <img src={analytics} alt="" />
              <h3>Raportointipalvelut palveluna</h3>
              <p>
                Raportointipalvelut automatisoituna ja aina saatavillasi
                laitteesta riippumatta.
              </p>
            </div>

            <div id="slot" style={{ padding: '0 15px', width: '80%' }}>
              <img src={teacher} alt="" />
              <h3>Kouluttaminen + Työpajat</h3>
              <p>Kaikki mitä sinun pitää tietää Microsoftin BI- Työkaluista.</p>
            </div>

            <div id="slot" style={{ padding: '0 15px', width: '80%' }}>
              <img src={business} alt="" />
              <h3>IT-Konsultointi</h3>
              <p>Monipuolinen IT-konsultointi Office 365 -ympäristöstä.</p>
            </div>
          </div>

          <hr
            style={{ width: '100%', margin: '0', border: '1px solid #000' }}
          />
          <p style={{ paddingTop: '30px' }}>
            <strong>
              <a
                id="contactLink"
                onClick={() => this.scrollToElement2('Yhteydenottopyynto')}
              >
                Ota yhteyttä
              </a>{' '}
              ja jutellaan yrityksesi tarpeista lisää!
            </strong>
          </p>
        </div>
      )
    }
  }
}

export default Palvelut
