import React from 'react'

import Landing from '../components/Landing.jsx'
import Referenssit from '../components/Referenssit.jsx'
import Palvelut from '../components/Palvelut.jsx'
import Yritys from '../components/Yritys.jsx'
import Yhteydenottopyynto from '../components/Yhteydenottopyynto.jsx'
import Yhteystiedot from '../components/Yhteystiedot.jsx'

export default function Frontpage() {
  return (
    <>
      <Landing />
      <Referenssit />
      <Palvelut />
      <Yritys />
      <Yhteydenottopyynto />
      <Yhteystiedot />
    </>
  )
}
