import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './Menu.scss'

class Menu extends React.Component {
  scrollToElement2 = (id) => {
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const coordY =
      window.scrollY + document.getElementById(id).getBoundingClientRect().top
    const quickMaths = coordY - viewportHeight / 7
    window.scrollTo({ left: 0, top: quickMaths, behavior: 'smooth' })
  }

  scrollToElement = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  render() {
    const { pathName, menuToggle } = this.props

    // Mobiili tietosuojaseloste
    if (pathName === '/tietosuojaseloste' && window.innerWidth < 1025) {
      return (
        <div className="Menu">
          <Link to="/" onClick={() => menuToggle()}>
            <button>
              <p>Palaa etusivulle</p>
            </button>
          </Link>

          {/* <div className="socialMedia">

        <button id="fb" onClick={ () => window.location.assign('https://fb.me/wilcode.fi')}>
        <FontAwesomeIcon icon={faFacebook} style={{fontSize:'8vmin', color:'rgba(0, 0, 0, 0.85)'}} />
        </button>

        <button id="ig" onClick={ () => window.location.assign('https://www.instagram.com/wilcode_oy/')}>
        <FontAwesomeIcon icon={faInstagram} style={{fontSize:'8vmin', color:'rgba(0, 0, 0, 0.85)'}} />
        </button>

      </div> */}
        </div>
      )

      // Mobiili etusivu
    } else if (window.innerWidth < 1025) {
      return (
        <div className="Menu">
          <button
            onClick={() => {
              window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
              menuToggle()
            }}
          >
            <p>Etusivu</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Referenssit')
              menuToggle()
            }}
          >
            <p>Referenssit</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement2('Palvelut')
              menuToggle()
            }}
          >
            <p>Palvelut</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement2('Yritys')
              menuToggle()
            }}
          >
            <p>Yritys</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement2('Yhteydenottopyynto')
              menuToggle()
            }}
          >
            <p>Yhteydenottopyyntö</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Yhteystiedot')
              menuToggle()
            }}
          >
            <p>Yhteystiedot</p>
          </button>

          {/* <div className="socialMedia">

        <button id="fb" onClick={ () => window.location.assign('https://fb.me/wilcode.fi')}>
        <FontAwesomeIcon icon={faFacebook} style={{fontSize:'8vmin', color:'rgba(0, 0, 0, 0.85)'}} />
        </button>
        
        <button id="ig" onClick={ () => window.location.assign('https://www.instagram.com/wilcode_oy/')}>
        <FontAwesomeIcon icon={faInstagram} style={{fontSize:'8vmin', color:'rgba(0, 0, 0, 0.85)'}} />
        </button>

      </div> */}
        </div>
      )
    }

    // Desktop tietosuojaseloste
    else if (pathName === '/tietosuojaseloste' && window.innerWidth >= 1025) {
      return (
        <div className="Menu" id="shorterMenu">
          <Link to="/" onClick={() => menuToggle()}>
            <button>
              <p>Palaa etusivulle</p>
            </button>
          </Link>

          <div className="socialMedia">
            <button id="fb">
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ fontSize: '8vmin', color: 'rgba(0, 0, 0, 0.85)' }}
              />
            </button>

            <button id="ig">
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ fontSize: '8vmin', color: 'rgba(0, 0, 0, 0.85)' }}
              />
            </button>
          </div>
        </div>
      )
    }
    // Desktop etusivu
    else {
      return (
        <div className="Menu">
          <button
            onClick={() => {
              window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
              menuToggle()
            }}
          >
            <p>Etusivu</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Referenssit')
              menuToggle()
            }}
          >
            <p>Referenssit</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Palvelut')
              menuToggle()
            }}
          >
            <p>Palvelut</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Yritys')
              menuToggle()
            }}
          >
            <p>Yritys</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Yhteydenottopyynto')
              menuToggle()
            }}
          >
            <p>Yhteydenottopyyntö</p>
          </button>

          <button
            onClick={() => {
              this.scrollToElement('Yhteystiedot')
              menuToggle()
            }}
          >
            <p>Yhteystiedot</p>
          </button>

          <div className="socialMedia">
            <button
              id="fb"
              // onClick={ () => window.location.assign('https://www.facebook.com/wilcode_oy/')}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ fontSize: '8vmin', color: 'rgba(0, 0, 0, 0.85)' }}
              />
            </button>

            {/* <a href="https://www.instagram.com/wilcode_oy/" target="_blank" rel="noopener noreferrer" alt="instagram" /> */}

            <button
              id="ig"
              onClick={() =>
                window.location.assign('https://www.instagram.com/wilcode_oy/')
              }
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ fontSize: '8vmin', color: 'rgba(0, 0, 0, 0.85)' }}
              />
            </button>
          </div>
        </div>
      )
    }
  }
}

export default Menu
