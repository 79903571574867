import React from 'react'
import { useForm } from 'react-hook-form'

import './Yhteydenottopyynto.scss';

export default function Yhteydenottopyynto() {

  const { register, handleSubmit, errors } = useForm()
  const onSubmit = () => {
    post('/send')
  }

  function post(path, params, method='post') {

    const form = document.getElementById("contactForm")
    form.method = method;
    form.action = path;
  
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
  
        form.appendChild(hiddenField);
      }
    }
  
    document.body.appendChild(form);
    form.submit()
  }

  return (
    <div className="Yhteydenottopyynto" id="Yhteydenottopyynto">
      <h2>Yhteydenottopyyntö</h2>
        <p>Voit lähettää yhteydenottopyynnön täyttämällä alla olevan lomakkeen. Vastaamme yhteydenottoihin mahdollisimman pian. Tähdellä (*) merkityt kentät ovat pakollisia tietoja.</p>

        <form className="contactForm" id="contactForm" method="POST" action="send" onSubmit={handleSubmit(onSubmit)}>

          <label htmlFor="name">Nimi *</label>
          <input name="name" ref={register({ required: true, minLength: 3 })} /> 

          <label htmlFor="company">Yritys</label>
          <input name="company" ref={register({ maxLength: 50 })} />

          <label htmlFor="email">Sähköposti *</label>
          <input name="email" ref={register({ required: true, minLength: 5 })} />

          <label htmlFor="phone">Puhelinnumero</label>
          <input name="phone" ref={register({ maxLength: 13 })} />

          <label htmlFor="message">Viesti</label>
          <textarea name="message" rows="9" ref={register({ maxLength: 9999 })} />

          <input type="submit" />
          {errors.name && <p id="error">Nimi on pakollinen tieto!</p>}
          {errors.email && <p id="error">Sähköposti on pakollinen tieto!</p>}

      </form>
      
    </div>

    )
}